<template>
  <div class="cell">
    <div :class="`status ${this.color}`"></div>
    <div class="value">{{ this.value }}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: '',
      color: '',
      isRenderer: '',
      colorList: ['green', 'red', 'yellow', 'orange', 'white', 'grey'],
      enumConfig: {
        logisticPortalExportControlFreeTimeStatus: {
          undefined: 'grey',
          freeTimeExpired: 'red',
          freeTimeExpiring: 'orange'
        }
      }
    }
  },
  beforeMount() {
    const colId = this.params.column.colId
    const value = this.params.value ?? this.params.data[colId]
    const fields = this.params?.api?.context?.meta?.fields
    const colField = fields?.find((x) => x.id === colId)
    if (value && colField) {
      if (colField.enumType) {
        this.color = this.colorList.find((x) => value.startsWith(x))
        if (this.color == null && this.enumConfig[colField.enumType] != null) {
          this.color = this.enumConfig[colField.enumType][value]
        }
        this.isRenderer = true
        this.value = this.$t(`application.enums.${colField.enumType}.${value}`)
      } else {
        this.value = value.value
        this.color = value.color
      }
    } else if (this.params.colDef?.enumType) {
      const enumType = this.params.colDef?.enumType
      const value = this.params.data[colId]?.value
      this.color = this.colorList.find((x) => value.startsWith(x))
      this.isRenderer = true
      this.value = this.$t(`application.enums.${enumType}.${value}`)
    } else if (value) {
      this.value = value.value
      this.color = value.color
    }
  }
}
</script>

<style lang="scss" scoped>
.cell {
  display: inline-flex;
  align-items: center;
  gap: 10px;
}

.value {
  text-transform: uppercase;
}

.status {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  &.white {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #ffffff9c;
  }
  &.grey {
    background: #959db5 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #959db580;
  }
  &.green {
    background: #81dd1f 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #81dd1f80;
  }
  &.orange {
    background: #ff9a00 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #ff9a0080;
  }
  &.red {
    background: #de0b3c 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #de0b3c80;
  }
  &.yellow {
    background: #f7c624 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #f7c6247c;
  }
}
</style>
